import React from 'react';
import './App.css';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const Home = (props) => {
  return (
    <div className="App">
      <FacebookShareButton url={window?.location?.href} quote={"გააზიარე"} className="share">
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={window?.location?.href} quote={"გააზიარე"} className="share">
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
    </div>
  )
}


const About = (props) => {
  let twTitle = document.querySelectorAll('meta[name="twitter:title"]')[0];
  let twDescription = document.querySelectorAll('meta[name="twitter:description"]')[0];
  let twImage = document.querySelectorAll('meta[name="twitter:image"]')[0];

  let ogTitle = document.querySelectorAll('meta[property="og:title"]')[0];
  let ogDescription = document.querySelectorAll('meta[property="og:description"]')[0];
  let ogImage = document.querySelectorAll('meta[property="og:image"]')[0];

  twTitle.content = "About Title Twitter";
  twDescription.content = "About Description Twitter";
  twImage.content = "https://images.unsplash.com/photo-1593642702909-dec73df255d7";

  ogTitle.content = "About Title Facebook";
  ogDescription.content =  "About Description Twitter";
  ogImage.content = "https://images.unsplash.com/photo-1593642702909-dec73df255d7";

  return (
    <div className="App">
      <FacebookShareButton url={window?.location?.href} quote={"გააზიარე"} className="share">
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={window?.location?.href} quote={"გააზიარე"} className="share">
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
    </div>
  )
}

const Users = (props) => {
  let twTitle = document.querySelectorAll('meta[name="twitter:title"]')[0];
  let twDescription = document.querySelectorAll('meta[name="twitter:description"]')[0];
  let twImage = document.querySelectorAll('meta[name="twitter:image"]')[0];

  let ogTitle = document.querySelectorAll('meta[name="og:title"]')[0];
  let ogDescription = document.querySelectorAll('meta[name="og:description"]')[0];
  let ogImage = document.querySelectorAll('meta[name="og:image"]')[0];

  twTitle.content = "Users Title Twitter";
  twDescription.content = "Users Description Twitter";
  twImage.content = "https://images.unsplash.com/photo-1602250798140-fb1513d8f6a1";

  ogTitle.content = "Users Title Facebook";
  ogDescription.content =  "Users Description Twitter";
  ogImage.content = "https://images.unsplash.com/photo-1602250798140-fb1513d8f6a1";

  return (
    <div className="App">
      <FacebookShareButton url={window?.location?.href} quote={"გააზიარე"} className="share">
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={window?.location?.href} quote={"გააზიარე"} className="share">
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
    </div>
  )
}


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
